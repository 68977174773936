<template>
	<div  class="editShop">
		<van-form>
			<van-field name="uploader" required label="门店图" :rules="[{ required: true, message: '请选择门店图' }]">
				<template #input>
					<van-uploader :max-count="1" name="image" :before-delete="afterDelete" :after-read="afterRead"
						v-model="image" />
				</template>
			</van-field>
			<van-field name="uploader" label="组图">
				<template #input>
					<van-uploader :before-delete="afterDelete" :after-read="afterRead" name="images" multiple
						:max-count="4" v-model="images" />
				</template>
			</van-field>
			<button @click="editBut" class="editBut">编辑</button>
		</van-form>
	</div>
</template>

<script>
	import {
		uploadImage
	} from "@/api/common"
	import {
		editImage,
		getImage
	} from "@/api/user"
	import {Toast} from "vant";
	export default{
		data(){
			return{
				formdata:{
					image:[],
					images:[]
				},
				uploads: [],
				image:[],
				images:[],
				rep:''
			}
		},
		methods:{
			afterDelete(file, detail) {
				switch (detail.name) {
					case 'image':
						this.formdata.image = ''
						this.image.splice(detail.index, 1)
						break
					case 'images':
						this.uploads.splice(detail.index, 1)
						this.images.splice(detail.index, 1)
						break
				}
			},
			afterRead(file, detail) {
				const formData = new FormData();
				formData.append('file', file.file)
				uploadImage(formData).then((res) => {
					if (detail.name === 'image') {
						this.formdata.image = res.data.path
					}else if(detail.name === 'images') {
						this.uploads.push(res.data.path)
					}
				})
			},
			editBut(){
				this.formdata.images = this.uploads.toString()
				this.rep = this.formdata.images.replace(/,/,';')
				let data = {}
				data.image = this.formdata.image
				data.slideshow = this.rep
				editImage(data).then((res) =>{
					// console.log(res)
					Toast.success(res.msg)
					this.$router.go(-1)
				})
			},
			getImage(){ 
				let data = {}
				data.id = this.$route.query.id
				getImage(data).then((res)=>{
					if (res.data.image) {
						this.image = [{
							url: 'https://server.esw235.cn/'+res.data.image
						}] 
					}
					if (this.formdata.images) {
						// this.uploads = res.data.images.split(",")
						for (const i in res.data.slideshow) {
							this.images.push({
								url: 'https://server.esw235.cn/'+res.data.slideshow[i]
							})
						}
					}
				})
			}
		},
		mounted() {
			if (this.$route.query.id) this.getImage()
			// console.log(this.$route.query.id)
		}
	}
</script>

<style lang="less" scoped>
	.editShop{
		width: 100%;
		.editBut{
			width: 80%;
			height: 40px;
			position: absolute;
			left: calc(50% - 40%);
			margin-top: 25px;
			color: #fff;
			background-color: #1989fa;
			border: 1px solid #1989fa;
			border-radius: 20px;
		}
	}
</style>
